.portfolio-id-heading {
  color: red;
  padding: 50px 0;
  font-size: 60px;
}

.portfolio-id-img {
  max-width: 100%;
}

.portfolio-id-text {
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.portfolio-id-link {
  background-color: red;
  color: white;
  padding: 20px 40px;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-decoration: none;
}
.portfolio-id-link:hover {
  color: rgb(212, 211, 211);
}
@media screen and (max-width: 768px) {
  .portfolio-id-heading {
    font-size: 48px;
    padding: 40px 0;
  }
}
