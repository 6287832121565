@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,500;0,600;0,700;1,400&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
}
:root {
  --bg-main: #90e0ef;
  --text-color: #29b6f6;
  --font-family: "Poppins", sans-serif;
  --black: #000000;
  --white: #ffffff;
}
