.counter {
  padding: 60px 0;
  background-color: #f7f8fb;
  margin-bottom: 70px;
  font-family: var(--font-family);
}
.counter .text {
  padding-left: 20px;
}
.counter .text .title_1 {
  opacity: 0.7;
}
.counter .text h2 {
  font-size: 40px;
}
.counter .text .title_2 {
  opacity: 0.9;
}
.counter figure {
  width: 180px;
}
.counter figure img {
  width: 100%;
  object-fit: cover;
}
.counter .count {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.counter .count .icon {
  color: var(--text-color);
  width: 40px;
  height: 40px;
}
.counter .count .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.counter .count .icon-yellow {
  color: #d7b03b;
}
.counter .count .icon-pink {
  color: #e4007d;
}
.counter .count .icon svg {
  width: 100%;
  height: 100%;
}
.counter .counter-item {
  margin-left: 20px;
}
.counter .counter-item p {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
