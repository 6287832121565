.main {
  width: 100%;
  height: 90vh;
  background-color: #f4f7fa;
  overflow: hidden;
  font-family: var(--font-family);
}
.main .contant {
  /* margin: 50px; */
  padding: 20px;
}
.main .contant .title {
  font-size: 35px;
  font-weight: 500;
}
.main .contant .title .Typewriter {
  color: black;
}
.main .contant button {
  background-color: var(--text-color);
  outline: none;
  border-color: var(--text-color);
  padding: 8px 20px;
}
.main .contant button:focus {
  box-shadow: none;
}

.main .contant .desc .type {
  font-size: 30px;
  font-weight: 600;
  font-style: italic;
}
.main .image {
  margin: 50px;
}
.main .image img {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@media (max-width: 600px) {
  .main {
    padding: 20px 0;
  }
  .main .contant {
    margin: 0;
  }
}

/* ------ Services ---- */

.services {
  margin: 70px 0;
}
.services .title {
  text-align: center;
  margin: 20px 0;
}

.services .services-details {
  box-shadow: 0 16px 32px 0 rgb(0 0 0 / 10%);
  padding: 25px 35px;
  transition: all 0.2s ease-in-out;
  text-align: center;
  /* position: relative; */
}
/* .services .services-details::before{
    content: "";
    position: absolute;
    background-color: #63a2ff;
    top: -8px;
    left: -1px;
    width: calc(100% + 2px);
    height: 8px;
    border-radius: 6px 6px 0 0;
} */

.services .services-details .image {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.services .services-details .image img {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.services .services-details:hover {
  /* transform: translateY(-10px); */
  box-shadow: 0 16px 32px 0 rgb(0 0 0 / 20%);
  /* transform: scale(1.1); */
  /* transition: all .2s ease-in-out; */

  /*  */
  box-shadow: 0 10px 60px 0 rgb(71 74 182 / 16%);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/* ==== Counter Section ==== */
/* .counter-section {
  margin: 70px 0;
}
.counter-section .counter-item {
  background-color: #f4f7fa;
  text-align: center;
  border-radius: 10px;
  padding: 30px 20px;
  margin: 20px;
}
.counter-section .counter-item p {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: var(--text-color);
} */
