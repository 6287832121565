.about-section {
  margin-bottom: 80px;
  font-family: var(--font-family);
}
.about-section .about {
  background-color: red;
}
.code {
  box-shadow: none !important;
  border: solid #0000002d 0.01rem !important;
  height: 65px;
}
.z {
  z-index: 999 !important;
}
.car {
  box-shadow: none;
}
.list {
  border-color: white !important;
  border: none;
}
.about-section .about .title p {
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 40px;
  margin: 0;
  padding: 100px 0;
}
.about-section .about-info h2 {
  position: relative;
  display: inline-block;
}
.about-section .about-info h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  width: 50%;
  height: 4px;
  background-color: red;
  border-radius: 12px;
}
.about-section .about-info p {
  font-size: 16px;
  margin: 30px 0;
}
