.careers-section {
  font-family: var(--font-family);
}
.careers-section .careers {
  background-color: red;
}
.careers-section .careers .title p {
  text-align: center;
  color: var(--white);
  font-weight: 600;
  font-size: 40px;
  margin: 0;
  padding: 100px 0;
}
.careers-section .careers-content {
  max-width: 70%;
  text-align: center;
  margin: 40px auto;
  font-family: var(--font-family);
}
.careers-section .careers-content .careers-title p {
  font-size: 40px;
  font-weight: 500;
  color: var(--black);
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

@media (max-width: 600px) {
  .careers-section .careers-content {
    max-width: 100%;
  }
}
.careers-section .careers-content .careers-title p::before {
  content: '';
  position: absolute;
  width: 35%;
  height: 5px;
  background-color: red;
  top: 55px;
  left: 75px;
  border-radius: 8px;
}
.careers-section .careers-content .careers-desc {
  margin-top: 30px;
}

.careers-section .careers-services {
  margin: 100px 0;
}
.careers-section .careers-services .service-bg {
  margin: 20px;
  padding: 30px;
  background-color: #fafafa;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  min-height: 350px;
  position: relative;
}
.careers-section .careers-services .service-bg:hover {
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  background-color: var(--white);
  transform: translateY(-5px);
  transition: all 0.2s ease-out;
}

.careers-section .careers-services .service-bg .service-img {
  width: 50px;
}
.careers-section .careers-services .service-bg .service-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.careers-section .careers-services .service-bg .service-img-node {
  width: 150px;
}
.careers-section .careers-services .service-bg .service-img-node img {
  width: 100%;
  height: 100%;
}

.careers-section .careers-services .service-bg .services-title {
  font-size: 25px;
  font-weight: 500;
  font-family: var(--font-family);
  margin: 10px 0;
}
.careers-section .careers-services .service-bg .services-title p {
  margin: 0;
}
.careers-section .careers-services .service-bg .services-btn {
  position: absolute;
  bottom: 30px;
}
.careers-section .careers-services .service-bg .services-btn button {
  background-color: red;
  outline: none;
  border-color: red;
  padding: 8px 20px;
}

.careers-section .careers-services .services-btn button:focus {
  box-shadow: none;
}

.careers-section .careers-services .service-bg .service-img-dotnet {
  width: 80px;
}
.careers-section .careers-services .service-bg .service-img-dotnet img {
  width: 100%;
  height: 100%;
}

.careers-services a {
  text-decoration: none;
}
.portfolio-card {
  overflow: hidden;
  margin-bottom: 25px;
  color: black;
}

.portfolio-card img {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 200ms ease-in-out;
}

.portfolio-card:hover img {
  transform: scale(1.05);
}

.portfolio-card .services-title {
  color: red;
  font-size: 24px;
}

.portfolio-card .serv {
  padding: 20px 40px;
}
