/** @format */

.header {
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07); */
  background-color: #f4f7fa;
  /* background-color: #caf0f8; */
  /* position: fixed; */
  position: sticky;
  top: 0;
  z-index: 3;
  transition: all 0.5s ease-in-out;
  padding: 20px 0;
}

.header.bg-color {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
  /* background-color: #caf0f8; */
  padding: 10px 0;
}
.header .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding:  0 15px ; */
}
.header .header-content .logo img {
  width: 150px;
}

.header .header-content .menu .menu-items a {
  text-decoration: none;
  color: #000;
  margin-right: 20px;
  font-size: 20px;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  padding: 5px 0;
}
.header .header-content .menu .menu-items a:last-child {
  margin-right: 0;
}

.header .header-content .menu .menu-items a.active {
  border-bottom: 3px solid var(--text-color);
  transition: border-bottom 0.5s ease-in-out;
}

.hamburger {
  display: none;
}

@media (max-width: 600px) {
  .hamburger {
    display: block;
    font-size: 25px;
  }
  .header .header-content .menu .menu-items {
    display: none;
  }
  .header .header-content .menu .mobile-menu-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: lightblue; */
    background-color: var(--text-color);
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09); */
    position: absolute;
    top: 100px;
    left: 0;
  }
  .header .header-content .menu .mobile-menu-items a {
    text-decoration: none;
    /* color: #000; */
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin: 12px 0;
  }
  .header .header-content .menu .mobile-menu-items a.active {
    border-bottom: 3px solid #fff;
    transition: border-bottom 0.5s ease-in-out;
  }
}

/* ======= Animated Navbar ======   */
/* ============================== */

.Navbar {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Navbar .logo img {
  width: 110px;
}

.Navbar .nav-items a {
  text-decoration: none;
  color: #000;
  margin-right: 20px;
  font-size: 20px;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  padding: 5px 0;
}
.Navbar .nav-items a.active {
  border-bottom: 3px solid red;
  transition: border-bottom 0.5s ease-in-out;
}
.Navbar .nav-toggle {
  display: none;
}

@media (max-width: 600px) {
  .Navbar .nav-items {
    position: absolute;
    top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f4f7fa;
    left: 0;
    width: 100%;
    transform: translateX(-100%);
    transition: all 0.45s;
    height: 100vh;
  }

  .Navbar .nav-items.open {
    transform: translateX(0);
  }
  .Navbar .nav-items.open a {
    color: #000;
  }
  .Navbar .nav-items.open a.active {
    border-bottom: 3px solid red;
    transition: border-bottom 0.5s ease-in-out;
  }

  .Navbar .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #000;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle .bar::before,
  .nav-toggle .bar::after {
    content: '';
    position: absolute;
    height: 2px;
    background: #000;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}
