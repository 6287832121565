.services {
  margin: 70px 0;
}
.services .services_title {
  text-align: center;
}
.services .services_title h1 {
  font-size: 35px;
  display: inline-block;
  position: relative;
}
.services .services_title h1::before {
  content: "";
  position: absolute;
  left: 54px;
  top: 50px;
  width: 50%;
  height: 4px;
  background-color: red;
  border-radius: 12px;
}
.services .card {
  padding: 25px 35px;
  background: linear-gradient(var(--white) 50%, red 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
  margin-top: 30px;
  overflow: hidden;
  min-height: 300px;
}
.services .card h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1f194c;
  margin: 12px 0;
}
.services .card p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
}
.services .card .icon-wrapper {
  background-color: red;
  position: relative;
  margin: 0 auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: var(--white);
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
.services .card:hover {
  background-position: 0 100%;
}
.services .card:hover .icon-wrapper {
  background-color: var(--white);
  color: red;
}
.services .card:hover h3 {
  color: var(--white);
}
.services .card:hover p {
  color: #f0f0f0;
}
