.scroll {
  position: fixed;
  right: 50px;
  bottom: 100px;
  height: 20px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
  /* color: #e5027e; */
}
